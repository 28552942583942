<template>
    <div>
        <v-spa-layout
            ref="layout"
            @toggle-sidebar="handleSidebar"
            @show-sidebar="handleSidebar"
            @hide-sidebar="handleSidebar"
            :display-responsive-menu-on-desktop="false"
            responsiveSidebarClass="p-6"
            responsiveMenuIcon="/spa/close-white.svg"
        >
            <layout-responsive-sidebar ref="responsiveSidebar" slot="header-sidebar"></layout-responsive-sidebar>
            <layout-sidebar ref="sidebar" slot="sidebar" v-if="display.sidebar"></layout-sidebar>
            <layout-content ref="content" slot="content">
                <slot name="content"></slot>
            </layout-content>
        </v-spa-layout>
    </div>
</template>

<script>
    import store from "@base/store";
    import LayoutSidebar from "@base/components/_Layout/Sidebar.vue";
    import LayoutResponsiveSidebar from "@base/components/_Layout/ResponsiveSidebar.vue";
    import LayoutContent from "@base/components/_Layout/Content.vue"

    export default {
        components: {
            LayoutSidebar,
            LayoutResponsiveSidebar,
            LayoutContent
        },
        data() {
            return {
                display: { sidebar: true }
            }
        },
        methods: {
            handleSidebar(status) {
                this.display = this.isHavingSidebar
                    ? status
                    : false;
            }
        },
        computed: {
            isHavingSidebar() {
                return !! store.state.layout?.sidebar;
            }
        },
        mounted() {
            if ( ! this.isHavingSidebar ) {
                this.$bus.$emit('spa:hideSidebar')
            }
        }
    }
</script>  