<template>
    <div class="flex-auto h-full relative max-h-screen overflow-y-auto"
        :class="{
            'bg-body': $store.state.layout.container, 
            'bg-white': ! $store.state.layout.container 
        }"
    >  
        <div
            class="h-full"
            :class="{ 'p-6 lg:py-12 lg:px-8': $store.state.layout.container }"
        >
            <div
                class="h-full"
                :class="{ 'pb-12 lg:max-w-container mx-auto': $store.state.layout.container }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>