import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes";

/** Route generator. */
export const generator = new RouteGenerator();

/**
 * Prefixing given name with account related details.
 * @param {string} name Name to prefix
 * @returns {string} Prefixed name.
 */
 export const getAccountRouteName = name => `account.${process.env.MIX_TRUSTUP_APP_KEY}.${name}`;