import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from "./messages";
import { default_locale } from "./locales"

Vue.use(VueI18n);
  
// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: default_locale,
	fallbackLocale: default_locale,
	messages,
});

export default i18n;