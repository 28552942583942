import { generator, getAccountRouteName } from "../utils";
import pages from "@base/pages";

// Account related routes.
const account_routes = [
    generator.create({
        path: 'scaffolding',
        name: getAccountRouteName('index'),
        component: pages.scaffolding,
    })
        .title("Scaffolding")
        .sidebar('scaffolding')
        .get(),
];

export default account_routes;