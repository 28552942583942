<template>
    <div class="">
        <master-layout>
            <template slot="content">
                <div
                    :class="{ 'h-full bg-body py-12 px-8': !$store.state.layout.container }"
                    v-if="loading"
                >
                    <v-spa-panel
                        panel-height="auto"
                        :loading="loading"
                    ></v-spa-panel>
                </div>

                <div
                    class="relative h-full"
                    v-if="isReady"
                >
                    <router-view :key="uniqueKey"></router-view>
                </div>
            </template>
        </master-layout>
    </div>
</template>

<script>

    import store from "@base/store";
    import MasterLayout from '~base/components/Layout.vue';

    export default {
        components: { MasterLayout },
        data() {
            return { loading: false }
        },
        methods: {},
        computed: {
            havingValidAccount() {
                return !!store.state.account?.uuid
            },
            isReady() {
                return !this.loading && this.havingValidAccount;
            },
            uniqueKey() {
                return `account-${store.state.account?.uuid}-${store.state.account?.updated_at}-${this.$route.fullPath}`;
            }
        },
        async created() {
            // General api call should happen here using await.
        }
    }
</script>
