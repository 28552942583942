<template>
    <div class="flex flex-col flex-1 overflow-y-auto">
        <div class="flex-auto py-2">
            <template v-if="$store.state.account">
                <v-spa-layout-sidebar-link
                    section="scaffolding"
                    :to="{ name: 'account.show', params: { account: $store.state.account.uuid } }"
                    icon="far fa-clock"
                >
                    scaffolding
                </v-spa-layout-sidebar-link>
            </template>
        </div>
        <div class="pt-6 pb-2">
            <div
                class="flex justify-center mt-2"
                v-if="displayAccountActivation"
            >
                <a
                    :href="subscriptionActivationLink"
                    class="p-2 text-sm font-thin text-white bg-green-600 rounded hover:bg-green-500 sm:block"
                >
                    <i class="lg:mr-2 far fa-check"></i>
                    <span class="hidden lg:inline-block">{{ $t('layout.sidebar.activate_account') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "@base/store";

    export default {
        computed: {
            subscriptionActivationLink() {
                return store.state.user?.links?.activate_subscription;
            },
            havingSubscriptionActivationLink() {
                return !!this.subscriptionActivationLink;
            },
            beingInTrial() {
                return !!store.state.account?.chargebee?.is_trial;
            },
            displayAccountActivation() {
                return this.beingInTrial && this.havingSubscriptionActivationLink;
            }
        }
    }
</script>
