<template>
    <div>
        <v-spa-layout-title>
            Scaffolding page
        </v-spa-layout-title>
        <span>This page is considered as account homepage.</span>
    </div>
</template>
​
<script>
export default {}
</script>